import { GetStaticPropsResult } from 'next'
import dynamic from 'next/dynamic'
import Error from 'next/error'

import { errorPageModuleQuery } from '@data/sanity/queries/page'
import { SanitySiteFragment } from '@data/sanity/queries/types/site'
import { SanityErrorPage } from '@data/sanity/queries/types/page'
import { getPageSanityClient } from '@lib/sanity/client'
import { defaultModuleQueryParameters, getErrorPage } from '@lib/sanity/page'
import { defaultLocale, Locale } from '@lib/language'
import { CustomErrorStaticPropsContext } from '@lib/routes'

import Layout from '@components/layout'
import Modules from '@modules/modules'
import PreviewModules from '@modules/preview-modules'

const PreviewProvider = dynamic(() => import('@lib/sanity/preview'))

interface ErrorPageProps {
  draftMode: boolean
  draftToken?: string
  locale: Locale
  page: SanityErrorPage | null
  site: SanitySiteFragment | null
}

export const NotFoundPage = ({
  draftMode,
  draftToken,
  locale,
  page,
  site,
}: ErrorPageProps) => {
  if (!page || !site) {
    return (
      <Error
        title={`"Error Page (404)" is not set in Sanity, or the page data is missing`}
        statusCode={404}
      />
    )
  }

  if (draftMode && draftToken) {
    return (
      <Layout page={page} site={site}>
        <PreviewProvider draftToken={draftToken}>
          <PreviewModules
            modules={page.modules}
            moduleQuery={errorPageModuleQuery}
            moduleQueryParams={{
              ...defaultModuleQueryParameters,
              locale,
            }}
          />
        </PreviewProvider>
      </Layout>
    )
  }

  return (
    <Layout page={page} site={site}>
      <Modules modules={page.modules} />
    </Layout>
  )
}

export async function getStaticProps(
  context: CustomErrorStaticPropsContext
): Promise<GetStaticPropsResult<ErrorPageProps>> {
  const [sanityClient, draftMode, draftToken] = getPageSanityClient(
    context.draftMode
  )
  const errorPage = await getErrorPage(
    sanityClient,
    context.locale ?? defaultLocale
  )

  return {
    props: {
      draftMode,
      draftToken,
      locale: context.locale ?? defaultLocale,
      page: errorPage.page,
      site: errorPage.site,
    },
    revalidate: 60,
  }
}

export default NotFoundPage
